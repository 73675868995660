@import 'styles/colors.scss';

.radio-button-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 30px;
  border: 1px solid $medium-grey;
  margin-right: 10px;

  .radio-button-selected {
    width: 22px;
    height: 22px;
    border-radius: 22px;
    background-color: $secondary-color;
  }
}

.radio-button-container {
  display: flex;
  flex-direction: row;

  .radio-button-label {
    align-self: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .radio-button-content {
    display: flex;
    flex-direction: row;

    .radio-button-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      padding-right: 10px;
    }
  }
  .disabled {
    opacity: 0.7;
  }
}
