@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/base-containers.scss';
@import '../../../../../../styles/input.scss';

.master-city-container {
  width: 100%;
  display: flex;
  background-color: #f7a11d;
  padding-bottom: 5px;
  padding-top: 15px;

  .master-city-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    @extend %base-padding-horizontal;
  }

  .master-city-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    background-color: #fff;
    color: #000;
    border-radius: 0;
    border-color: #f7a11d;
    border-width: 3px;
    border-style: solid;
  }
}

.first-form-container {
  padding-bottom: 24px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $secondary-color;

  .first-form-content {
    @extend %base-padding-horizontal;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    input:disabled {
      // background-color: #ddd !important;
      opacity: 0.7;
    }

    input::-webkit-input-placeholder {
      color: gray;
    }
    input:-moz-placeholder {
      color: gray;
    }
    input:-ms-input-placeholder {
      color: gray;
    }

    .validation-error {
      color: red;
    }

    input:focus,
    input:active,
    select:focus,
    select:active {
      outline: none;
      // box-shadow: none;
      // border: 0;
    }

    .disabled-option {
      color: gray;
      option {
        color: #000;
      }
    }
  }

  .react-datepicker__input-container input {
    &::placeholder {
      /* Firefox, Chrome, Opera */
      color: gray;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: gray;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: gray;
    }
  }

  .react-datepicker-popper {
    left: -55px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  input,
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    background-color: #fff;
    color: #000;
    border-radius: 0;
    border-color: $secondary-color;
    border-width: 3px;
    border-style: solid;

    @include placeholder {
      color: #000;
    }
  }
  .react-datepicker__day--today {
    border-radius: 0.3rem;
    border: 1px solid #216ba5;
  }

  // select option:disabled{
  //   color: grey;
  // }

  input[type='date'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    background-color: #fff;
    height: 52px;
    color: #000;
    font-size: 16px;
    border-radius: 0;
    border-color: $secondary-color;
    border-width: 3px;
    border-style: solid;
  }

  input[type='date']:not(.has-value) {
    // color: grey;
    &:before {
      color: white;
      content: attr(placeholder);
    }
  }

  input[type='date']::-ms-clear {
    display: none;
  }
}

.first-form-content,
.master-city-container {
  .is-invalid {
    label, input::placeholder {
      color: #ff0000cc;
    }

    input,
    select {
      border-color: #ff000099 !important;
    }
    textarea{
      border-bottom: 1px solid #ff000099 !important;
    }
  }
}
