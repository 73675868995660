@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/base-containers.scss';
@import '../../../../../../styles/input.scss';

div.save-as-backdrop {
  background-color: transparent;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.form-footer {
  // position: fixed;
  position: sticky;

  left: 0;
  right: 0;
  bottom: 0;
  background-color: $light-grey;

  display: flex;
  height: 72px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: grey;

  .button-dark,
  .button-secondary,
  .button,
  .button-orange {
    @media (max-width: 900px) {
      width: 150px;
    }
  }

  .button.disabled {
    opacity: 0.5;
  }

  .content {
    @extend %base-padding-horizontal;

    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .score-overall-container {
      display: flex;
      height: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      min-width: 225px;

      .content {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      p {
        color: $dark-grey;
        font-size: 18px;
        margin: 0px;
      }

      .score {
        font-size: 28px;
        font-family: 'OpenSans-SemiBold';
        margin-left: 12px;
      }
    }

    .write-note-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 240px;
      height: 54px;
      background-color: $dark-grey;
      font-family: 'OpenSans-SemiBoldItalic';
      color: white;
      border-width: 0px;
      font-size: 20px;
      transform: skew(-30deg);
    }

    .icon-edit {
      display: inline-block;
      height: 24px;
      width: 24px;
      background: url('../../../../../../assets/icons/note.svg') no-repeat;
      background-size: contain;
      margin-right: 12px;
    }

    .button-content {
      display: flex;
      transform: skew(30deg);
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .edit {
      background-color: $are-four-color;
      font-family: 'OpenSans-SemiBoldItalic';
    }
  }

  p {
    color: white;
    margin-left: 12px;
  }

  p.alert-message {
    color: black;
  }

  .sub-rows {
    display: flex;
    width: auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .save-as-button {
    background-color: $primary-color;
    border-radius: 2px;
    width: 120px;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .save-and-send-button {
    background-color: $secondary-color;
    border-radius: 2px;
    width: 160px;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .bubble {
    position: absolute;
    top: -11vh;
    //width: 25vw;
    height: 10vh;
    padding: 0px;
    background: #ffffff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: $medium-grey solid 2px;
    // left: 54%;
    width: 225px;

    @media (max-width: 900px) {
      left: -21%;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      font-size: 24px;
      font-family: 'OpenSans-SemiBoldItalic';

      .first-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 50%;
        width: 100%;
      }

      .first-row.underlined {
        border-bottom: 2px solid $medium-grey;
      }

      .second-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 50%;
        width: 100%;
      }
    }
  }

  .bubble:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px 10px 0;
    border-color: #ffffff transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -9px;
    left: calc(50% - 10px);
  }

  .bubble:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 11px 11px 0;
    border-color: $medium-grey transparent;
    display: block;
    width: 0;
    z-index: 0;
    bottom: -12px;
    left: calc(50% - 11px);
  }
}

// @media only screen and (min-width: 1201px) {
//   .form-footer {
//     width: 1152px;
//     position: static;
//     bottom: 0;

//     margin-left: auto;
//     margin-right: auto;
//   }
// }
