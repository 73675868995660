@import './colors.scss';

button {
  outline: 0;
}

.button {
  // background: url('./assets/img/button-background.svg') no-repeat;
  background-position: center;
  background-size: contain;
  color: white;
  width: 240px;
  height: 54px;
  font-family: 'OpenSans-SemiBoldItalic';
  font-size: 20px;
  border-width: 0px;
  background: transparent;
  position: relative;
  z-index: 0;
  // transform: skew(30deg);
  &.square {
    // background: url('./assets/img/button-background-square.svg') no-repeat;
    // background-color: #216eab;
    background-position: center;
    background-size: contain;
  }
}

.button::before {
  content: '';
  position: absolute;
  width: 100%;
  max-width: 185px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #216eab;
  border: 0 none;
  border-radius: 0;
  padding: 0;
  transform: skew(-30deg);
  z-index: -1;
  // margin-top: -13px;
  // margin-left: -6.5px;
  left: 3px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  @media (max-width: 900px) {
    left: 0px;
  }
}

.button.square::before {
  transform: skew(0);
  max-width: 185px;
  height: 54px;
}

.button-secondary {
  // background: url('./assets/img/button-secondary-bkg.svg') no-repeat;
  background-position: center;
  background-size: contain;
  color: $primary-light-color;
  width: 240px;
  height: 54px;
  font-family: 'OpenSans-SemiBoldItalic';
  font-size: 20px;
  border-width: 0px;
  background: transparent;
  position: relative;
  z-index: 0;

  &.square {
    // background: url('./assets/img/button-secondary-bkg-square.svg') no-repeat;
    background-position: center;
    background-size: contain;
  }

  @media (max-width: 900px) {
    text-align: center;
  }

  @media (max-width: 768px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.button-secondary::before {
  content: '';
  position: absolute;
  width: 100%;
  max-width: 185px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 3px solid #216eab;
  border-left: 4px solid #216eab;
  border-right: 4px solid #216eab;
  border-radius: 0;
  padding: 0;
  transform: skew(-30deg);
  z-index: -1;
  // margin-top: -13px;
  // margin-left: -6.5px;
  left: 3px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  @media (max-width: 900px) {
    left: 0px;
  }
}

.button-secondary.square::before {
  transform: skew(0);
  border-left: 3px solid #216eab;
  border-right: 3px solid #216eab;
}

.button-dark {
  // background: url('./assets/img/button-dark-bkg.svg') no-repeat;
  background-position: center;
  background-size: contain;
  color: white;
  width: 240px;
  height: 54px;
  font-family: 'OpenSans-SemiBoldItalic';
  font-size: 20px;
  border-width: 0px;
  background: transparent;
  position: relative;
  z-index: 0;

  &.square {
    font-size: 16px;
    background-image: none;
    background-color: #3f3f3f;
    width: 110px;
    height: 40px;
  }
}

.button-dark::before {
  content: '';
  position: absolute;
  width: 100%;
  max-width: 185px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3f3f3f;
  border: 0 none;
  border-radius: 0;
  padding: 0;
  transform: skew(-30deg);
  z-index: -1;
  // margin-top: -13px;
  // margin-left: -6.5px;
  left: 3px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  @media (max-width: 900px) {
    left: 0px;
  }
}

.button-dark.square::before {
  transform: skew(0);
}

.button-light {
  // background: url('./assets/img/button-light-bkg.svg') no-repeat;
  background-position: center;
  background-size: contain;
  color: white;
  width: 240px;
  height: 54px;
  font-family: 'OpenSans-SemiBoldItalic';
  font-size: 20px;
  border-width: 0px;
  background: transparent;
  position: relative;
  z-index: 0;
  &.square {
    // background: url('./assets/img/button-light-bkg-square.svg') no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.button-light::before {
  content: '';
  position: absolute;
  width: 100%;
  max-width: 185px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cdcdcd;
  border: 0 none;
  border-radius: 0;
  padding: 0;
  transform: skew(-30deg);
  z-index: -1;
  // margin-top: -13px;
  // margin-left: -6.5px;
  left: 3px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  @media (max-width: 900px) {
    left: 0px;
  }
}

.button-light.square::before {
  transform: skew(0);
}

.button-orange {
  // background: url('./assets/img/button-orange-bkg.svg') no-repeat;
  background-position: center;
  background-size: contain;
  color: white;
  width: 240px;
  height: 54px;
  font-family: 'OpenSans-SemiBoldItalic';
  font-size: 20px;
  border-width: 0px;

  background: transparent;
  position: relative;
  z-index: 0;

  &.square {
    // background: url('./assets/img/button-orange-bkg-square.svg') no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.button-orange::before {
  content: '';
  position: absolute;
  width: 100%;
  max-width: 185px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7a11d;
  border: 0 none;
  border-radius: 0;
  padding: 0;
  transform: skew(-30deg);
  z-index: -1;
  // margin-top: -13px;
  // margin-left: -6.5px;
  left: 3px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  @media (max-width: 900px) {
    left: 0px;
  }
}

.button-orange.square::before {
  transform: skew(0);
  max-width: 185px;
  height: 54px;
}

.button-close {
  border: none;
  width: 50px;
  height: 50px;
  color: transparent;
  background-color: transparent;
  background-image: url('./assets/img/close-icon-b.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}

.buttons-row p {
  width: auto;
  min-width: 75px;
}

.buttons-row-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 !important;
}

.button-filter-sort.button {
  width: 48% !important;
  height: 30px !important;
  padding: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;

  &:before {
    width: auto;
  }
}
.button-filter-sort.button-secondary::before {
  height: 24px !important;
  width: auto;
}

.button-filter-sort.button-secondary {
  // padding-left: 6px;
  width: 48% !important;
}

.buttons-wrapper button + button {
  margin-left: 25px;
}

.button-filter-date {
  padding: 0 5px;

  width: 48% !important;
  height: 30px !important;
  padding: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;

  &:before {
    width: auto;
  }

  &:after {
    content: '';
    display: inline-block;
    background-image: url('./assets/img/arrow-up-down.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 10px;
    height: 19px;
    margin-bottom: -2px;
    margin-left: 5px;
  }
  &.button-invert {
    &:after {
      transform: rotate(180deg);
    }
  }
  &.button-secondary {
    &:after {
      background-image: url('./assets/img/arrow-up-down-no.svg');
    }
    &::before {
      width: auto;
      height: 24px !important;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
    width: 48% !important;
  }
}

.form-footer {
  .button-secondary {
    &:before {
      @media (max-width: 900px) {
        margin-left: -4px;
      }
    }
  }
}
