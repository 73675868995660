@import 'styles/colors.scss';

.protection-specification-root {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;

  .protection-specification-title {
    color: $dark-grey;
    font-size: 16px;
  }

  .protection-specification-info {
    padding-left: 10px;
    color: $medium-grey;
  }

  .protection-specification-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid silver;
    padding-top: 0px;
  }

  .button-items-input {
    display: flex;
    width: 100%;
    background-color: $white;
    align-items: center;
    margin-top: 10px;
  }
  .button-items-label {
    padding: 10px;
  }

  .accordion-content {
    display: flex;
  }

  .protection-specification-subtitle {
    padding-top: 15px;
    font-style: italic;
  }

  .protection-specification-button-tooltip {
    border: 1px solid #216eab;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: 10px;
    margin-right: 0;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    // position: absolute;
    right: -10px;
    margin-right: 10px;

    @media (max-width: 768px) {
      margin-left: 12px;
      margin-right: -34px;
    }
  }
  .action-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    span {
      font-size: 12px;
      text-decoration: underline;
    }
  }
}
