@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/base-containers.scss';
@import '../../../../../../styles/input.scss';

.areas-header-container {
  //display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: center;
  width: 100%;

  .title-container {
    display: flex;
    width: 12%;
    min-width: min-content;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 20px;
  }

  .is-invalid {
    color: #ff0000cc;
  }

  .tabs-rating-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    line-height: normal;
    margin-top: 10px;

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 32px;
    }

    p {
      color: white;
      font-size: 0.65em;
      text-align: center;
      font-family: 'OpenSans-SemiBold';
      margin: 0px;
    }
  }
}

.table-areas {
  @extend %base-padding-horizontal;
  @extend %base-padding-vertical;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;

  width: 100%;

  .tr-header-areas {
    padding-bottom: 12px;
  }

  tr,
  tbody {
    display: flex;
    flex-direction: row;
    width: 100%;

    .th-area-name {
      display: flex;
      width: 40%;
      align-items: left;
    }

    .td-area-name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 6px;
      width: 40%;
      height: 64px;
      border-width: 1px;
      border-color: rgba(0, 0, 0, 0.2);
      border-style: solid;

      input {
        border-width: 0px;
        padding-left: 0px;
      }
    }

    .th-area-average {
      display: flex;
      width: 40%;
      align-items: center;
      justify-content: center;
    }

    .td-area-average {
      display: flex;
      flex-direction: row;
      //align-items: center;
      justify-content: center;
      align-items: center;
      padding-left: 6px;
      width: 40%;
      height: 64px;
      border-width: 1px;
      border-color: rgba(0, 0, 0, 0.2);
      border-style: solid;

      .average-mark {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        top: 0;
        width: 0;
        height: auto;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid;
        margin-bottom: -10px;

        span {
          font-family: 'OpenSans-SemiBold';
          font-size: 18px;
          margin: 0px;
          padding-top: 22px;
        }
      }

      %area-rating-base-div {
        left: 0px;
        border-color: transparent;
      }

      .area-one {
        @extend %area-rating-base-div;
        left: 7.3vw;
        border-bottom-color: $are-one-color;
        color: $are-one-color;
      }

      .area-two {
        @extend %area-rating-base-div;
        left: 13.5vw;
        border-bottom-color: $are-two-color;
        color: $are-two-color;
      }

      .area-three {
        @extend %area-rating-base-div;
        left: 19.4vw;
        border-bottom-color: $are-three-color;
        color: $are-three-color;
      }

      .area-four {
        @extend %area-rating-base-div;
        left: 25.5vw;
        border-bottom-color: $are-four-color;
        color: $are-four-color;
      }

      .area-five {
        @extend %area-rating-base-div;
        left: 28.5vw;
        border-bottom-color: $are-five-color;
        color: $are-five-color;
      }

      .average-item {
        width: 20%;

        span {
          font-size: 12px;
          margin: 0px;
          opacity: 0.5;
        }
      }
    }

    .th-area-files {
      display: flex;
      width: 10%;
      align-items: center;
      justify-content: center;
    }

    .td-area-files {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 10%;
      height: 64px;
      border-width: 1px;
      border-color: rgba(0, 0, 0, 0.2);
      border-style: solid;
      cursor: pointer;

      span {
        text-decoration: underline;
        font-size: 12px;
        margin: 0px;
      }

      .photos-number {
        position: relative;
        top: -8px;
        right: -12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: red;

        span {
          font-family: 'OpenSans-SemiBold';
          font-size: 10px;
          color: white;
          margin: 0px;
          text-decoration: none;
        }
      }

      .camera-icon {
        width: 20px;
        height: 20px;
        background: url('../../../../../../assets/icons/camera.svg') no-repeat;
        background-position: center;
        background-size: contain;
        margin: 0px;
      }
    }

    .th-area-action {
      display: flex;
      width: 10%;
      align-items: center;
      justify-content: center;
    }

    .td-area-action {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 10%;
      height: 64px;
      border-width: 1px;
      border-color: rgba(0, 0, 0, 0.2);
      border-style: solid;
      cursor: pointer;

      span {
        text-decoration: underline;
        font-size: 12px;
        margin: 0px;
      }

      .trash-icon {
        width: 20px;
        height: 20px;
        background: url('../../../../../../assets/icons/delete-button.svg')
          no-repeat;
        background-position: center;
        background-size: contain;
        margin: 0px;
      }
    }
  }

  .blocked {
    opacity: 0.5;
  }

  .disabled-grey {
    color: #ccc;
  }
}

.add-new-area {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  margin-left: 24px;
}

.third-form-container {
  @extend %base-padding-horizontal;
  @extend %base-padding-vertical;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;

  .row-div {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 1201px) {
  .third-form-container {
    width: 96%;
  }
}
