@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/base-containers.scss';

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: $secondary-color;
  width: 100%;
  height: auto;

  .header-content {
    @extend %base-padding-horizontal;
    padding-top: 5px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    background-color: #003f6a;

    h1 {
      margin: 10px 0 5px;
    }

    p {
      color: white;
      margin: 0px;
    }

    .back-button-container {
      position: absolute;
      top: 19px;
      right: 45px;
    }
  }
}

.hamb-menu-icon {
  background: url('../../../../../../assets/icons/hamb.svg') no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 26px;
  left: 27px;
}
