@import './styles/colors.scss';
@import './styles/buttons.scss';
@import './styles/fonts.scss';
@import './styles/icons.scss';
@import './styles/modals.scss';

body {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  color: #515151;

  -webkit-font-smoothing: antialiased;
}

h1 {
  font-family: 'OpenSans-BoldItalic';
  font-size: 40px;
  line-height: 1;
  margin: 20px 0 5px;
  color: #ffffff;
}

textarea, input, button, select { font-family: OpenSans-Regular;}
// textarea { font-family: inherit; font-size: inherit; }


input[type=number] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h2 {
  font-family: 'OpenSans-BoldItalic';
  text-transform: uppercase;
  font-size: 30px;
  line-height: 27px;
  margin: 5px 0 10px;
  color: #ffffff;
}

h3 {
  font-family: 'OpenSans-BoldItalic';
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  text-transform: uppercase;
  color: #ffffff;
}

h4 {
  font-family: 'OpenSans-BoldItalic';
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
  text-transform: uppercase;
  text-transform: uppercase;
  text-transform: uppercase;
  color: #ffffff;
}

.sun-editor-editable h1 {
  font-family: 'OpenSans-BoldItalic';
  font-size: 40px;
  line-height: 1;
  margin: 20px 0 5px;
  color: black;
}

.sun-editor-editable h2 {
  font-family: 'OpenSans-BoldItalic';
  text-transform: uppercase;
  font-size: 30px;
  line-height: 27px;
  margin: 5px 0 10px;
  color: black;
}

.sun-editor-editable h3 {
  font-family: 'OpenSans-BoldItalic';
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  text-transform: uppercase;
  color: black;
}

.sun-editor-editable h4 {
  font-family: 'OpenSans-BoldItalic';
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
  text-transform: uppercase;
  text-transform: uppercase;
  text-transform: uppercase;
  color: black;
}

small {
  font-size: 12px;
  line-height: 17px;
  color: inherit;
}

a {
  font-family: 'OpenSans-SemiBold';

  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  text-decoration: none;

  color: inherit;
}

address {
  font-style: normal;
}

dl {
  color: #004671;
  margin-bottom: 10px;
}

dt {
  display: inline-block;
}

dd {
  display: inline-block;
  font-family: 'OpenSans-Regular';
  font-style: italic;
  font-size: 20px;
  margin-left: 5px;
}

input:disabled {
  background-color: transparent;
}

@keyframes cssProgressActive {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 35px 35px;
  }
}

.rc-tooltip-content {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 300px;
  width: 300px;
}

.rc-tooltip-arrow {
  display: none;
}

.react-datepicker__triangle {
  margin-left: 65px !important;
}

.react-datepicker-popper {
  left: -55px;
}

.tm {
  vertical-align: super;
  // font-size: 9px;
  // font-weight: bold;
}

.sm {
  font-size: initial;
  // font-weight: bold;
}

.react-pdf__Page__textContent{
  display: none !important;
}

.react-pdf__Document{
  padding-bottom: 60px !important;
}

.react-pdf__Page__canvas{
  margin: auto;
}
