@import 'styles/colors.scss';

.client-info-root {
  width: 100%;
  display: flex;
  background-color: $grey-section;

  .client-info-title {
    color: $dark-grey;
  }

  .client-info-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    position: relative;
  }
}
