@import "styles/colors.scss";

.cancellation-clause-root {
  width: 100%;
  display: flex;
  background-color: $grey-section;

  .cancellation-clause-title {
    color: $dark-grey;
  }

  .cancellation-clause-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
  }

  .cancellation-row {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .cancellation-column {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: center;
    align-items: center;
  }

  .cancellation-label {
    margin-bottom: 10px;
  }
}
